import axios from "axios";
import Vue from "vue";
import {processHttpErrors} from "@/helpers/commonHelper";
import {saveFile} from "@/helpers/mediaHelper";

export class ProductService{
    formDataProduct = {};
    formDataProductBrand = {};
    formDataProductCat = {};
    formDataProductGroup = {};
    formDataProductUnit = {};
    constructor() {
        this.formDataProduct = {};
        this.formDataProductBrand = {};
        this.formDataProductCat = {};
        this.formDataProductGroup = {};
        this.formDataProductUnit = {};
    }

    async searchProduct(filters, page){
        let api_endpoint = Vue.prototype.$globalSettings.api.endpoints.product.products;
        if(page){
            api_endpoint += '?page=' + page
        }
        return await axios.post(api_endpoint, filters)
            .then((response) => {
                return {isSuccess: response.data.status === 'success', message: response.data.msg, products: response.data.products.data? response.data.products.data: response.data.products, last_page: response.data.products.last_page, balances: response.data.balances};
            })
            .catch((error) => {
                processHttpErrors(error, true);
                return {isSuccess: false, message: 'Something went wrong!', products: [], last_page: 1, balances: []};
            });
    }

    async getProduct(id, mode){
        return await axios.post(Vue.prototype.$globalSettings.api.endpoints.product.product, {
            id: id,
            mode: mode,
        })
            .then((response) => {
                return {isSuccess: response.data.status === 'success', message: response.data.msg, product: response.data.product};
            })
            .catch((error) => {
                processHttpErrors(error, true);
                return {isSuccess: false, message: 'Something went wrong!', product: null};
            });
    }

    async saveProduct(upload_image){
        return await axios.post(Vue.prototype.$globalSettings.api.endpoints.product.productSave, this.formDataProduct).then(async (response) => {
            if(upload_image) {
                if (!await saveFile(response.data.id, upload_image, Vue.prototype.$globalSettings.api.endpoints.product.productSaveFile)) {
                    return {isSuccess: false, message: 'Image upload failed!', product: null};
                }
            }
            return {isSuccess: response.data.status === 'success', message: response.data.msg, product: response.data.product};
        }).catch((error) => {
            processHttpErrors(error, true);
            return {isSuccess: false, message: 'Something went wrong!', product: null};
        });
    }

    async delete(id){
        return await axios.post(Vue.prototype.$globalSettings.api.endpoints.product.productDelete, {
            id: id
        })
            .then((response) => {
                return {isSuccess: response.data.status === 'success', message: response.data.msg};
            })
            .catch((error) => {
                processHttpErrors(error, true);
                return {isSuccess: false, message: 'Something went wrong!'};
            })
    }

    //Brand
    async getBrands(fetch){
        let productBrands = Vue.prototype.$store.getters['common/productBrands'];
        if(productBrands.length <= 0 || fetch){
            return await axios.post(Vue.prototype.$globalSettings.api.endpoints.productBrand.productBrands)
                .then((response) => {
                    if(response.data.status === 'success'){
                        productBrands = response.data.productBrands;
                        Vue.prototype.$store.dispatch('common/setProductBrands', {value: productBrands});
                    }
                    return {isSuccess: response.data.status === 'success', message: response.data.msg, productBrands: productBrands};
                })
                .catch((error) => {
                    processHttpErrors(error, true);
                    return {isSuccess: false, message: 'Something went wrong!', productBrands: []};
                });
        }
        return {isSuccess: true, message: '', productBrands: productBrands};
    }

    async getProductBrand(id, mode){
        return await axios.post(Vue.prototype.$globalSettings.api.endpoints.productBrand.productBrand, {
            id: id,
            mode: mode,
        })
            .then((response) => {
                return {isSuccess: response.data.status === 'success', message: response.data.msg, productBrand: response.data.productBrand};
            })
            .catch((error) => {
                processHttpErrors(error, true);
                return {isSuccess: false, message: 'Something went wrong!', productBrand: null};
            });
    }
    async saveProductBrand(upload_image){
        return await axios.post(Vue.prototype.$globalSettings.api.endpoints.productBrand.productBrandSave, this.formDataProductBrand).then(async (response) => {
            if(upload_image) {
                if (!await saveFile(response.data.id, upload_image, Vue.prototype.$globalSettings.api.endpoints.productBrand.productBrandSaveFile)) {
                    return {isSuccess: false, message: 'Image upload failed!', productBrand: null, data: null};
                }
            }
            await this.getBrands(true);
            return {isSuccess: response.data.status === 'success', message: response.data.msg, productBrand: response.data.productBrand, data: response.data.data};
        }).catch((error) => {
            processHttpErrors(error, true);
            return {isSuccess: false, message: 'Something went wrong!', productBrand: null, data: null};
        });
    }

    async deleteProductBrand(id){
        return await axios.post(Vue.prototype.$globalSettings.api.endpoints.productBrand.productBrandDelete, {
            id: id
        })
            .then(async (response) => {
                await this.getBrands(true);
                return {isSuccess: response.data.status === 'success', message: response.data.msg};
            })
            .catch((error) => {
                processHttpErrors(error, true);
                return {isSuccess: false, message: 'Something went wrong!'};
            })
    }

    //Cat
    async getCats(fetch){
        let productCats = Vue.prototype.$store.getters['common/productCats'];
        if(productCats.length <= 0 || fetch){
            return await axios.post(Vue.prototype.$globalSettings.api.endpoints.productCat.productCats)
                .then((response) => {
                    if(response.data.status === 'success'){
                        productCats = response.data.productCats;
                        Vue.prototype.$store.dispatch('common/setProductCats', {value: productCats});
                    }
                    return {isSuccess: response.data.status === 'success', message: response.data.msg, productCats: productCats};
                })
                .catch((error) => {
                    processHttpErrors(error, true);
                    return {isSuccess: false, message: 'Something went wrong!', productCats: []};
                });
        }
        return {isSuccess: true, message: '', productCats: productCats};
    }
    async getProductCat(id, mode){
        return await axios.post(Vue.prototype.$globalSettings.api.endpoints.productCat.productCat, {
            id: id,
            mode: mode,
        })
            .then((response) => {
                return {isSuccess: response.data.status === 'success', message: response.data.msg, productCat: response.data.productCat};
            })
            .catch((error) => {
                processHttpErrors(error, true);
                return {isSuccess: false, message: 'Something went wrong!', productCat: null};
            });
    }
    async saveProductCat(upload_image){
        return await axios.post(Vue.prototype.$globalSettings.api.endpoints.productCat.productCatSave, this.formDataProductCat).then(async (response) => {
            if(upload_image) {
                if (!await saveFile(response.data.id, upload_image, Vue.prototype.$globalSettings.api.endpoints.productCat.productCatSaveFile)) {
                    return {isSuccess: false, message: 'Image upload failed!', productCat: null, data: null};
                }
            }
            await this.getCats(true);
            return {isSuccess: response.data.status === 'success', message: response.data.msg, productCat: response.data.productCat, data: response.data.data};
        }).catch((error) => {
            processHttpErrors(error, true);
            return {isSuccess: false, message: 'Something went wrong!', productCat: null, data: null};
        });
    }

    async deleteProductCat(id){
        return await axios.post(Vue.prototype.$globalSettings.api.endpoints.productCat.productCatDelete, {
            id: id
        })
            .then(async (response) => {
                await this.getCats(true);
                return {isSuccess: response.data.status === 'success', message: response.data.msg};
            })
            .catch((error) => {
                processHttpErrors(error, true);
                return {isSuccess: false, message: 'Something went wrong!'};
            })
    }

    //Group
    async getGroups(fetch){
        let productGroups = Vue.prototype.$store.getters['common/productGroups'];
        if(productGroups.length <= 0 || fetch){
            return await axios.post(Vue.prototype.$globalSettings.api.endpoints.productGroup.productGroups)
                .then((response) => {
                    if(response.data.status === 'success'){
                        productGroups = response.data.productGroups;
                        Vue.prototype.$store.dispatch('common/setProductGroups', {value: productGroups});
                    }
                    return {isSuccess: response.data.status === 'success', message: response.data.msg, productGroups: productGroups};
                })
                .catch((error) => {
                    processHttpErrors(error, true);
                    return {isSuccess: false, message: 'Something went wrong!', productGroups: []};
                });
        }
        return {isSuccess: true, message: '', productGroups: productGroups};
    }
    async getProductGroup(id, mode){
        return await axios.post(Vue.prototype.$globalSettings.api.endpoints.productGroup.productGroup, {
            id: id,
            mode: mode,
        })
            .then((response) => {
                return {isSuccess: response.data.status === 'success', message: response.data.msg, productGroup: response.data.productGroup};
            })
            .catch((error) => {
                processHttpErrors(error, true);
                return {isSuccess: false, message: 'Something went wrong!', productGroup: null};
            });
    }
    async saveProductGroup(){
        return await axios.post(Vue.prototype.$globalSettings.api.endpoints.productGroup.productGroupSave, this.formDataProductGroup).then(async (response) => {
            await this.getGroups(true);
            return {isSuccess: response.data.status === 'success', message: response.data.msg, productGroup: response.data.productGroup, data: response.data.data};
        }).catch((error) => {
            processHttpErrors(error, true);
            return {isSuccess: false, message: 'Something went wrong!', productGroup: null, data: null};
        });
    }

    async deleteProductGroup(id){
        return await axios.post(Vue.prototype.$globalSettings.api.endpoints.productGroup.productGroupDelete, {
            id: id
        })
            .then(async (response) => {
                await this.getGroups(true);
                return {isSuccess: response.data.status === 'success', message: response.data.msg};
            })
            .catch((error) => {
                processHttpErrors(error, true);
                return {isSuccess: false, message: 'Something went wrong!'};
            })
    }

    //Unit
    async getUnits(fetch){
        let productUnits = Vue.prototype.$store.getters['common/productUnits'];
        if(productUnits.length <= 0 || fetch){
            return await axios.post(Vue.prototype.$globalSettings.api.endpoints.productUnit.productUnits)
                .then((response) => {
                    if(response.data.status === 'success'){
                        productUnits = response.data.productUnits;
                        Vue.prototype.$store.dispatch('common/setProductUnits', {value: productUnits});
                    }
                    return {isSuccess: response.data.status === 'success', message: response.data.msg, productUnits: productUnits};
                })
                .catch((error) => {
                    processHttpErrors(error, true);
                    return {isSuccess: false, message: 'Something went wrong!', productUnits: []};
                });
        }
        return {isSuccess: true, message: '', productUnits: productUnits};
    }
    async getProductUnit(id, mode){
        return await axios.post(Vue.prototype.$globalSettings.api.endpoints.productUnit.productUnit, {
            id: id,
            mode: mode,
        })
            .then((response) => {
                return {isSuccess: response.data.status === 'success', message: response.data.msg, productUnit: response.data.productUnit};
            })
            .catch((error) => {
                processHttpErrors(error, true);
                return {isSuccess: false, message: 'Something went wrong!', productUnit: null};
            });
    }
    async saveProductUnit(){
        return await axios.post(Vue.prototype.$globalSettings.api.endpoints.productUnit.productUnitSave, this.formDataProductUnit).then(async (response) => {
            await this.getUnits(true);
            return {isSuccess: response.data.status === 'success', message: response.data.msg, productUnit: response.data.productUnit, data: response.data.data};
        }).catch((error) => {
            processHttpErrors(error, true);
            return {isSuccess: false, message: 'Something went wrong!', productUnit: null, data: null};
        });
    }

    async deleteProductUnit(id){
        return await axios.post(Vue.prototype.$globalSettings.api.endpoints.productUnit.productUnitDelete, {
            id: id
        })
            .then(async (response) => {
                await this.getUnits(true);
                return {isSuccess: response.data.status === 'success', message: response.data.msg};
            })
            .catch((error) => {
                processHttpErrors(error, true);
                return {isSuccess: false, message: 'Something went wrong!'};
            })
    }
}